import { Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { ResetAsset } from '../../../types'
import {
    isImageAsset,
    isPersonalizedVideoAsset,
    isQuoteAsset
} from '../../../utils/utils'
import { quoteCardBackgroundColor } from '../../elements/QuoteCard/constants'
import ThrivePhotoCard from './ThrivePhotoCard'
import ThriveQuoteCard from './ThriveQuoteCard'
import { ResetIframePlayerWithFallback } from '@thriveglobal/thrive-web-leafkit'

export type FrameSlideProps = {
    frame: ResetAsset
    paused?: boolean
    playbackStartedOnce?: boolean
    isVisible?: boolean
}

function FrameSlide({
    frame,
    paused = false,
    playbackStartedOnce = false,
    isVisible = false
}: FrameSlideProps) {
    const playerRef = useRef<any>(null)
    const [isPlayerReady, setIsPlayerReady] = useState(false)
    const [hasStartedOnce, setHasStartedOnce] = useState(false)

    // Handle play/pause
    useEffect(() => {
        // We need to check if the player is ready because the player may not be ready
        if (isPersonalizedVideoAsset(frame) && isPlayerReady) {
            try {
                const player = playerRef.current
                if (paused || !isVisible) {
                    player.pause()
                } else if (playbackStartedOnce && isVisible) {
                    // If this is the first time playing, ensure we start from the beginning
                    if (!hasStartedOnce) {
                        player.currentTime = 0
                        setHasStartedOnce(true)
                    }
                    player.play()
                }
            } catch (error) {
                console.warn('Failed to control video player:', error)
            }
        }
    }, [
        paused,
        playbackStartedOnce,
        frame,
        isPlayerReady,
        isVisible,
        hasStartedOnce
    ])

    const handleVideoCanPlay = () => {
        setIsPlayerReady(true)
        if (!playbackStartedOnce) {
            playerRef.current?.pause()
        }
    }

    return (
        <Box height="100%" width="100%" data-testid="FrameSlide">
            {isQuoteAsset(frame) && (
                <ThriveQuoteCard
                    id={frame.id}
                    quote={frame.text}
                    author={frame.author}
                    textColor={frame.textColor}
                    backgroundColor={quoteCardBackgroundColor}
                    fullScreen
                />
            )}
            {isImageAsset(frame) && (
                <ThrivePhotoCard
                    id={frame.id}
                    src={frame.urls?.xl}
                    fullScreen
                />
            )}
            {isPersonalizedVideoAsset(frame) && (
                <ResetIframePlayerWithFallback
                    src={frame.urls.playback}
                    data-testid="ResetIframePlayerWithFallback"
                    ref={playerRef}
                    muted={true}
                    controls={false}
                    loop={true}
                    volume={0}
                    onCanPlay={handleVideoCanPlay}
                    onPlay={handleVideoCanPlay}
                />
            )}
        </Box>
    )
}

export default React.memo(FrameSlide)
