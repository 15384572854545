import { useCallback, useState } from 'react'
import {
    Feedback,
    GetThriveAudioResetsQuery,
    ThriveResetAudio,
    useClearResetAudioFeedbackMutation,
    useCreateResetAudioFeedbackMutation
} from '../../graphql/generated/autogenerated'
import { useApolloClientCacheEvict } from '../useApolloClientCacheEvict'
import { useAppDispatch } from '../../slices'
import { upsertOneThriveAudioReset } from '../../slices/thriveAudioResets'

export type UseAudioResetFeedbackResult = {
    isLoading: boolean
    updateFeedback: (
        feedback: Feedback,
        reset: ThriveResetAudio
    ) => Promise<void>
}

function useAudioResetFeedback(): UseAudioResetFeedbackResult {
    const [createResetFeedback] = useCreateResetAudioFeedbackMutation()
    const [clearResetFeedback] = useClearResetAudioFeedbackMutation()

    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useAppDispatch()
    const { evictFieldFromCache } = useApolloClientCacheEvict()

    const handleFeedback = useCallback(
        async (feedback: Feedback, reset: ThriveResetAudio) => {
            if (isLoading) {
                return
            }
            setIsLoading(true)
            try {
                const updatedReset: ThriveResetAudio = {
                    ...reset,
                    latestFeedback: feedback
                }
                dispatch(upsertOneThriveAudioReset(updatedReset))

                if (feedback === null) {
                    await clearResetFeedback({
                        variables: {
                            clearId: reset.id
                        }
                    })
                } else {
                    await createResetFeedback({
                        variables: {
                            feedback,
                            createId: reset.id
                        }
                    })
                }

                evictFieldFromCache<GetThriveAudioResetsQuery>(
                    'reset',
                    'thriveAudio'
                )
            } finally {
                setIsLoading(false)
            }
        },
        [
            createResetFeedback,
            dispatch,
            evictFieldFromCache,
            isLoading,
            clearResetFeedback
        ]
    )

    return {
        isLoading,
        updateFeedback: handleFeedback
    }
}

export default useAudioResetFeedback
