import React, {
    useEffect,
    useMemo,
    useState,
    useCallback,
    PropsWithChildren
} from 'react'
import { Box } from '@mui/material'
import { ClassNames } from '@emotion/react'
import { appearingNew as appearing } from './animations'
import useRestartAnimations from '../../../hooks/useRestartAnimations'
import { defaultFramesCount, defaultDuration } from './constants'
import {
    getAppearingAnimationDuration,
    getAppearingAnimationDelay
} from './utils'
import { framesZIndex } from '../PersonalizedResetPlayer/constants'
import { FrameSlideProps } from './FrameSlide'

export type FrameSwitchAnimationProps = {
    audioDuration?: number
    framesCount?: number
    index: number
    paused: boolean
    playbackStartedOnce?: boolean
    restartAnimationToken?: number
    children: React.ReactElement<FrameSlideProps>
}

function FrameSwitchAnimation(props: FrameSwitchAnimationProps) {
    const {
        audioDuration,
        framesCount,
        index,
        children,
        paused = false,
        playbackStartedOnce = false,
        restartAnimationToken
    } = props

    const [isVisible, setIsVisible] = useState(false)
    const { addElementWithAnimation, restartAnimationsOnElements } =
        useRestartAnimations()

    const frameDuration =
        (audioDuration || defaultDuration) / (framesCount || defaultFramesCount)

    const handleAnimationStart = useCallback((e: React.AnimationEvent) => {
        if (e.animationName === appearing.name) {
            setIsVisible(true)
        }
    }, [])

    const handleAnimationEnd = useCallback((e: React.AnimationEvent) => {
        setIsVisible(false)
    }, [])

    const appearingAnimationStyles = useMemo(() => {
        // If playback hasn't started, don't apply any animation styles
        if (!playbackStartedOnce) {
            return {
                opacity: 100
            }
        }

        const appearingDuration = `${getAppearingAnimationDuration(
            frameDuration
        )}ms`
        const appearingDelay = `${getAppearingAnimationDelay(
            frameDuration,
            index
        )}ms`

        return {
            opacity: 0,
            animation: appearing,
            animationName: appearing.name,
            animationDirection: 'normal',
            animationIterationCount: 1,
            animationFillMode: 'forwards',
            animationTimingFunction: 'ease-in-out',
            animationDuration: appearingDuration,
            animationDelay: appearingDelay,
            animationPlayState: paused ? 'paused' : 'running'
        }
    }, [frameDuration, index, paused, playbackStartedOnce])

    useEffect(() => {
        if (restartAnimationToken && playbackStartedOnce) {
            restartAnimationsOnElements()
        }
    }, [
        restartAnimationToken,
        restartAnimationsOnElements,
        playbackStartedOnce
    ])

    // Clone child and pass isVisible prop
    const childWithVisibility = React.cloneElement(children, { isVisible })

    return (
        <ClassNames>
            {({ css, cx }) => (
                <Box
                    position="absolute"
                    width="100%"
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    className={cx(css(appearingAnimationStyles))}
                    data-testid="FrameSwitchAppearingAnimation"
                    ref={addElementWithAnimation}
                    onAnimationStart={handleAnimationStart}
                    onAnimationEnd={handleAnimationEnd}
                    sx={{
                        zIndex: framesZIndex - index
                    }}
                >
                    {childWithVisibility}
                </Box>
            )}
        </ClassNames>
    )
}

export default React.memo(FrameSwitchAnimation)
