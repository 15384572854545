import {
    store,
    ThriveApplication as ThriveApplicationOriginal
} from '@thriveglobal/thrive-web-core'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import ResponsiveDndProvider from './components/elements/ResponsiveDndProvider'
import { cacheOptions } from './config/apollo'
import Router from './routes'
import { reducers } from './slices'

const loadi18n = (lang: string) =>
    fetch(`${process.env.CF_SOURCE_URL}/lang/${lang}.json`)
        .then((res) => res.json())
        .catch(console.warn)

type ThriveApplicationProps = React.PropsWithChildren<
    React.ComponentProps<typeof ThriveApplicationOriginal>
>
const ThriveApplication =
    ThriveApplicationOriginal as unknown as React.ComponentType<ThriveApplicationProps>

export default function Root() {
    useEffect(() => {
        // NOTE: Workaround. injectSlice params type is invalid
        store.injectSlice(reducers as any)
    }, [])

    return (
        <ThriveApplication
            messagesLoaders={[loadi18n]}
            commitHash={process.env.GIT_REF}
            apolloClientOptions={{
                name: 'thrive-web-reset',
                cacheOptions
            }}
            disableMaxWidth
        >
            <Provider store={store}>
                <ResponsiveDndProvider>
                    <section>
                        <Router />
                    </section>
                </ResponsiveDndProvider>
            </Provider>
        </ThriveApplication>
    )
}

// Global __THRIVE__ object
declare global {
    interface Window {
        __THRIVE__: any
    }
}
;(window.__THRIVE__ = window.__THRIVE__ || []).push({
    app: process.env.APP_NAME,
    version: process.env.APP_VERSION,
    created: process.env.CREATED_AT
})
