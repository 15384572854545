export enum Routes {
    Home = '/reset',
    Learn = '/learn',
    LearnReset = '/learn/reset',
    NewPersonalizedReset = '/reset/new',
    NewPersonalizedResetGallery = '/reset/new/gallery',
    NewPersonalizedResetVideo = '/reset/new/videos',
    NewPersonalizedResetQuote = '/reset/new/quotes',
    EditPersonalizedReset = '/reset/edit/:id',
    ThriveReset = '/reset/thrive/:id',
    PersonalizedReset = '/reset/personal/:id',
    ThriveAudioReset = '/reset/thrive/audio/:id',
    RandomReset = '/reset/thrive/random',
    DailyReset = '/reset/thrive/today'
}
